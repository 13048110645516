import coriander_brash_img from './img/coriander_brash.jpg'
import coriander_explode_img from './img/coriander_explode.png'
import coriander_pwork_img from './img/coriander_pwork.jpg'
import coriander_pwork2_img from './img/coriander_pwork2.jpg'
import coriander_sclass_img from './img/coriander_sclass.png'
import coriander_side_img from './img/coriander_side.jpg'
import coriander_soldark_img from './img/coriander_soldark.jpg'

const CorianderPage = [
  {
    type: 'header',
    value: 'Coriander'
  },
  {
    type: 'updates',
    updates: [
      {date: '11-13-2021', content: "I spent the day putting together the bundles for shipping. All kits have been bundled, qc'd and are ready for shipping. I will be shipping all the kits this coming week."},
      {date: '10-28-2021', content: 'I received the coriander case parts today and they look fantastic! I am currently in the process of shipping DCS Classic and will move on to Coriander as soon as those keysets finish shipping. Estimate to begin shipping Coriander is about a week'},
      {date: '10-23-2021', content: 'Coriander case parts are enroute to me. As soon as I receive them I will begin packaging and shipping. If you need to update your shipping address, please send an email to evan@thevankeyboards.com'},
      {date: '10-17-2021', content: 'Chris is finishing up the bead blast on the copper rings. He is currently waiting on new bead blast media. Once those are finished he will ship the cases to me. I\'d expect those to arrive in about 2 weeks. If you need to update your shipping address or have any other concerns, please send an email to evan@thevankeyboards.com'}
    ]
  },
  {
    type: 'text',
    value: 'The Coriander keyboard case is milled from sheets of corian countertop. The case consists of a corian top, bottom and ramp, copper accent ring and pom plate. The case supports MiniVan compatible PCBs'
  },
  {
    type: 'image',
    value: coriander_brash_img
  },
  {
    type: 'text',
    value: 'The following items are included in the kit'
  },
  {
    type: 'list',
    values: [
      'White Corian top, bottom and ramp',
      'POM Universal Plate',
      'Cork strip for feet',
      'Stencil for cork feet',
      'Case, plate, and ramp screws',
      'Copper Accent Ring'
    ],
    lg:4
  },
  {
    type: 'image',
    value: coriander_sclass_img,
    lg: 8
  },
  {
    type: 'image',
    value: coriander_explode_img,
    lg: 4
  },
  {
    type: 'image',
    value: coriander_side_img,
    lg: 4
  },
  {
    type: 'image',
    value: coriander_soldark_img,
    lg: 4
  },
  {
    type: 'image',
    value: coriander_pwork_img,
    lg: 6
  },
  {
    type: 'image',
    value: coriander_pwork2_img,
    lg: 6
  },
  {
    type: 'html',
    value: '<iframe width="560" height="315" src="https://www.youtube.com/embed/SPb9FrCzqHs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  }
]

export default CorianderPage;
