import Accordion from 'react-bootstrap/Accordion'
import { UpdatesProps } from './types'
import './style.css'

function Updates(props: UpdatesProps) {
  const { updates } = props

  return (
    <div className="UpdatesComponent">
      <h5>Updates</h5>
      <Accordion defaultActiveKey="0">
        {updates.map((update, index) => (
          <Accordion.Item eventKey={String(index)}>
            <Accordion.Header>{update.date}</Accordion.Header>
            <Accordion.Body>
              {update.content}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )
}

export default Updates
