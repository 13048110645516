import hub4img from './img/hub4.jpg'
import hub_blank_set_img from './img/hub_blank_set.png'
import hub_blanks_profile_img from './img/hub_blanks_profile.png'
import hub_blanks_top_img from './img/hub_blanks_top.png'
import hub1img from './img/hub1.jpg'
import hub2img from './img/hub2.jpg'
import hub3img from './img/hub3.jpg'
import hub5img from './img/hub5.jpg'
import hub6img from './img/hub6.jpg'

const HubPage = [
  {
    type: 'header',
    value: 'HuB - In Stock Order Now'
  },
  {
    type: 'image',
    value: hub4img
  },
  {
    type: 'html',
    value: `
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="FUY8MD4AVTHUW">
<table>
<tr><td><input type="hidden" name="on0" value="Color">Color</td></tr><tr><td><select name="os0">
	<option value="White">White $50.00 USD</option>
	<option value="Black">Black $50.00 USD</option>
</select> </td></tr>
</table>
<input type="hidden" name="currency_code" value="USD">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>
`
  },
  {
    type: 'text',
    value: 'These keycaps have been manufactured and are on hand. Orders ship in 1-2 days.'
  },
  {
    type: 'text',
    value: 'The HuB keycap profile is a cross between DSA and Cherry profiles. It is similar to DSS but a little less aggresive. For the last few months I have been working closely with a prototype injection molding company. The goal of this project is to produce 500 sets of HuB keycaps so that a decent sample of keyboard users can try the profile. If the reception is good and people enjoy the profile, the next step would be to partner with a vendor to make production level molds with broader keyboard support. These keycaps are being produced in black and white ABS and have no legends. A set of HuB caps has coverage for Preonic, Planck, MiniVan, V4N4G0N, JD40, JD45, and many other 40% keyboards.'
  },
  {
    type: 'image',
    value: hub_blanks_top_img
  },
  {
    type: 'image',
    value: hub_blanks_profile_img
  },
  {
    type: 'image',
    value: hub_blank_set_img
  },
  {
    type: 'image',
    value: hub5img
  },
  {
    type: 'image',
    value: hub1img
  },
  {
    type: 'image',
    value: hub6img
  },
  {
    type: 'image',
    value: hub2img
  },
  {
    type: 'image',
    value: hub3img
  }
]

export default HubPage;
