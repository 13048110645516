import ambassador_thumb from '../DynamicPage/dxxAmbassador/img/SA_Ambassador_MV_4K_thumb.png'

const data:any = [
  {
    name: 'SA Ambassador $150',
    img: ambassador_thumb,
    description: 'New keyset to be done in SA Profile',
    url: '/page/sa-ambassador'
  }
];

export default data;
