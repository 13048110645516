import data from './data'
import CardList from '../../Components/CardList'

function ClosedGroupBuys() {
  return (
    <div className="ClosedGroupBuys">
      <h3>Closed Group Buys</h3>
      <CardList cards={data} />
    </div>
  );
}

export default ClosedGroupBuys
