import data from './data'
import CardList from '../../Components/CardList'

function ComingSoon() {
  return (
    <div className="ComingSoon">
      <h3>Coming Soon</h3>
      <CardList cards={data} />
    </div>
  );
}

export default ComingSoon
