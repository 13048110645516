import full_kit from './img/image001.png'
import mv from './img/SA_Ambassador_MV_4K.png'
import v4n from './img/SA_Ambassador_v4n.png'
import v4n2 from './img/SA_Ambassador_v4n_2.png'

const DxxAmbassador = [
  {
    type: 'header',
    value: 'SA Ambassador $150'
  },
  {
    type: 'text',
    value: 'Sculpted SA set using stock colors GTL, NN, VBV and RCJ. Deep dish F and J. This set is estimated to complete production January 15th 2024. 100 total sets are being produced.'
  },
  {
    type: 'html',
    value: `<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="QWL2RQQWU3MLC">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  },
  {
    type: 'html',
    value: `<div class="alert alert-warning" role="alert">
  Note: This keyset is not expected to ship until early 2024. Please be aware of this when purchasing. Refunds will not be provided except for very extreme circumstances. Please send any address update requests to <a href="mailto:evan@thevankeyboards.com">evan@thevankeyboards.com</a>
</div>`
  },
  {
    type: 'image',
    value: full_kit
  },
  {
    type: 'image',
    value: mv,
    lg: 4
  },
  {
    type: 'image',
    value: v4n,
    lg: 4
  },
  {
    type: 'image',
    value: v4n2,
    lg: 4
  }
]

export default DxxAmbassador
