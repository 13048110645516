import dcs_classic_img from './img/dcs_classic.png'

const DcsClassicPage = [
  {
    type: 'header',
    value: 'DCS Classic Preorder $100'
  },
  {
    type: 'updates',
    updates: [
      {date: '10-28-2021', content: 'I received the remade spacebars from SP today and confirmed they are correct. I have begun shipping out sets requiring address change. If you need to update your address, please email me ASAP at evan@thevankeyboards.com. I will begin shipping the remaining orders over the next week.'},
      {date: '10-20-2021', content: 'I received the keysets from SP yesterday. They look amazing, but unfortunately the 9.75u and 10u spacebars has standard stab mounts instead of the requested 7u stab mounts. SP has acknowledged the mistake and is remaking the spacebars for me now. Shipping will begin once I receive the remakes.'}
    ]
  },
  {
    type: 'text',
    value: 'DCS Classic is a keyset bringing the aesthetic of the past into modern key compatibility'
  },
  {
    type: 'image',
    value: dcs_classic_img
  },
  {
    type: 'text',
    value: 'These caps are expected to take about 3 months to produce. The 9.75 and 10u bars have 7u stabilizer mounts.'
  }
]

export default DcsClassicPage;
