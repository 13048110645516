import science_img from './img/science.jpg'
import vanagon_pin_img from './img/Vanagon_pin.jpeg'
import lost_in_space_img from './img/lost_in_space.jpg'

const GalleryPage = [
  {
    type: 'header',
    value: 'Gallery'
  },
  {
    type: 'image',
    value: vanagon_pin_img,
    md: 4
  },
  {
    type: 'image',
    value: science_img,
    md: 8
  },
  {
    type: 'image',
    value: lost_in_space_img,
  }
]

export default GalleryPage
