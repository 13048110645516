import './style.css'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { CardListProps } from './types'
import { useHistory } from 'react-router-dom'

function CardList(props: CardListProps) {
  const { cards } = props
  const history = useHistory()
  const handleClick = (url: string) => {
    history.push(url)
  }

  return (
    <Row>
      {cards.map(item => (
        <Col xxl={3} lg={4} md={6} sm={12} key={item.url}>
          <Card bg="dark" className="Item" onClick={() => handleClick(item.url)} style={{width: '18rem'}}>
            <Card.Img variant="top" src={item.img} style={{objectFit: 'cover', height: '150px'}} />
            <Card.Body>
              <Card.Title>{item.name}</Card.Title>
              <Card.Text>
                {item.description}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default CardList
