import HubPage from './hub'
import { DataType } from './types'
import GalleryPage from './gallery'
import DsaPaperworkPage from './dsaPaperwork'
import NauticalPcbsPage from './nauticalPcbs'
import DssNeonNightsPage from './dssNeonNights'
import GarbageTruckPage from './garbageTruck'
import HullR1Page from './hullR1'
import VanagonR3Page from './vanagonR3'
import DcsClassicPage from './dcsClassic'
import CorianderPage from './coriander'
import WdamkgPage from './wdamkg'
import HubSpotlightPage from './hubSpotlight'
import AriaPage from './aria'
import VanuPage from './vanu'
import DxxAmbassador from './dxxAmbassador'
import HullR2Page from './hullR2'
import Stab425Page from './425stab'
import ClosetSalePage from './closetSale'
import embracePage from './embrace'
import goonPage from './goon'
import embraceExtrasPage from './embrace/extras'

const data: DataType = {
  hub: HubPage,
  gallery: GalleryPage,
  'dsa-paperwork': DsaPaperworkPage,
  'nautical-pcbs': NauticalPcbsPage,
  'dss-neon-nights': DssNeonNightsPage,
  'garbage-truck': GarbageTruckPage,
  'hull-r1': HullR1Page,
  'vanagon-r3': VanagonR3Page,
  'dcs-classic': DcsClassicPage,
  coriander: CorianderPage,
  wdamkg: WdamkgPage,
  'hub-spotlight': HubSpotlightPage,
  aria: AriaPage,
  vanu: VanuPage,
  'sa-ambassador': DxxAmbassador,
  'hull-r2': HullR2Page,
  '425-stab': Stab425Page,
  'closet-sale': ClosetSalePage,
  'embrace': embracePage,
  'goon-pcb': goonPage,
  'embrace-extras': embraceExtrasPage
};

export default data;
