import embrace_img from './img/embrace-assembled.jpg'
import embrace_btn from './img/btn_embrace.gif'
import pcb_btn from './img/btn_pcb.gif'

const embraceExtrasPage = [
  {
    type: 'header',
    value: 'Embrace Keyboard Kit Extras $575, PCB $65'
  },
  {
    type: 'image',
    value: embrace_img,
    md: 6,
    mdOffset: 3
  },
  {
    type: 'html',
    value: `<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="GYH4F7AQ7SFZC">
<input type="image" src="${embrace_btn}" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`,
    md: 3,
    mdOffset: 3
  },
  {
    type: 'html',
    value: `<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="NKJAGEBQYXY8C">
<input type="image" src="${pcb_btn}" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`,
    md: 3
  },
  {
    type: 'text',
    value: 'The Embrace keyboard kit is the only keyboard you\'ll ever need. With a super sweet leafspring mounting system, you\'ll have the perfect bounce with every keypress. Numbers 1-6 make any game a breeze. Full Are Gee Bee back-lighting lets all your neighbors know you\'re 1337. The hotswap pcb makes it easy to change your feel whenever you desire.'
  },
  {
    type: 'text',
    value: 'The Embrace keyboard kit includes the following'
  },
  {
    type: 'list',
    values: [
      'Machined acrylic top and bottom',
      'Stainless steel mid',
      'Stainless steel 25mm knob',
      '625 and Split space FR4 plates',
      'Hotswap PCB',
      'Knob daughter board',
      'USB C daughter board',
      'Custom molded feet',
      'Assembly hardware and jst cables'
    ],
    lg:4
  },
  {
    type: 'html',
    value: `<div class="alert alert-info" role="alert">
  Note: Switches, stabilizers, keycaps and usb cable are not included. You will need to source these yourself.
</div>`
  }
]

export default embraceExtrasPage
