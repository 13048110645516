import data from './data'
import BlogList from '../../Components/BlogList'

function ClosedGroupBuys() {
  return (
    <div className="ClosedGroupBuys">
      <h3>Blog</h3>
      <BlogList blogs={data} />
    </div>
  );
}

export default ClosedGroupBuys
