import './style.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Home() {
  return (
    <Row>
      <Col sm={12} className="Home">
        <h3>Death of TVK, Birth of Trash Man</h3>
        <p>TheVan Keyboards began April 1st 2016 with the launch of TheVan44 group buy. TheVan44 would eventually be rebranded as the MiniVan keyboard. My initial goal with that first group buy was to produce two boards for myself. One for home and one for the office. TheVan Keyboards closed in October of 2019.</p>
        <p>Late 2019 I began work on what would eventually be called the V4N4G0N. It was a MiniVan layout with half numrow designed purely for myself with the intent to be used for gaming, primarily League of Legends.</p>
        <p>I began referring to my new work as designed by trashman. This gave a clean break from the era of TVK. I have designed several keyboards under this new moniker.</p>
        <ul>
          <li>Garbage Truck</li>
          <li>C2, a successor to CaraVan</li>
          <li>Hull</li>
          <li>Aria</li>
          <li>Coriander</li>
        </ul>
        <p>It's been fun rediscovering the hobby and designing fun and unique boards that wouldn't have necessarily fit the TVK brand.</p>
      </Col>
    </Row>
  );
}

export default Home
