import hullagon_img from './img/hullagon.png'
import dsa_hullagon_img from './img/dsa_hullagon.png'
import hullagon2_img from './img/hullagon2.png'
import silver_hullagon_img from './img/silver_hullagon.png'

const VanagonR3Page = [
  {
    type: 'header',
    value: 'V4N4G0N R3 (Hullagon) $225 + free shipping'
  },
  {
    type: 'updates',
    updates: [
      {date: '3-25-2022', content: 'I have completed bundling all kits. I have shipped about 2/3 of the orders and continue to push more out each day. Extra parts will get listed here after GB shipping concludes'},
      {date: '2-16-2022', content: 'Anodizer claims that half of the cases should be shipped by this coming Friday'},
      {date: '10-28-2021', content: 'I received word that the cases should be finished up within the next couple weeks. If I get tracking or anything more specific I will post another update here.'},
      {date: '10-12-2021', content: 'All parts for the kits are ready except for the cases. The cases are with the anodizer who is currently short staffed and backed up. Once the cases are anodized and shipped to me, I will be packing and shipping the kits asap. If you have any questions about your order or need to update your shipping addres, please send an email to evan@thevankeyboards.com'}
    ]
  },
  {
    type: 'text',
    value: 'V4N4G0N R3 features a new low pro case design. The low pro case is bottom mount, meaning the plate attaches to the bottom of the case. The plate is suspended between the mount points allowing for a flexy bounce while typing. This mounting method provides an ultimate typing feel and sound on a budget.'
  },
  {
    type: 'image',
    value: hullagon_img
  },
  {
    type: 'text',
    value: 'These kits are expected to take about 3 months to produce. Each kit contains the following items.'
  },
  {
    type: 'list',
    values: [
      'Silver anodized aluminum case',
      '(4) Silver M2x6 screws',
      '(4) Cork Feet',
      'Full Space Layout FR4 Plate',
      'V4N4G0N PCB',
      'DSA Hullagon Keyset'
    ]
  },
  {
    type: 'image',
    value: hullagon2_img,
    lg: 6
  },
  {
    type: 'image',
    value: silver_hullagon_img,
    lg: 6
  },
  {
    type: 'image',
    value: dsa_hullagon_img
  }
]

export default VanagonR3Page;
