import hub4img from '../DynamicPage/hub/img/hub4.jpg'
import stab_img from '../DynamicPage/425stab/img/425stab.jpg'
import goon_pcb_img from '../DynamicPage/goon/img/goon-pcb_render_top.png'
import embrace_img from '../DynamicPage/embrace/img/embrace-assembled.jpg'

const data = [
  {
    name: '425 Stab 2 Pack',
    img: stab_img,
    description: 'Stabilizer for 425 spacebars',
    url: '/page/425-stab'
  },
  {
    name: 'Goon PCB',
    img: goon_pcb_img,
    description: 'Full size mods in a minivan footprint',
    url: '/page/goon-pcb'
  },
  {
    name: 'Embrace Extras',
    img: embrace_img,
    description: 'Extra\'s for the highly acclaimed Embrace keyboard',
    url: '/page/embrace-extras'
  }
];

export default data;
