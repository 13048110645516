import hull_img from './img/hull.jpg'

const HullR1Page = [
  {
    type: 'header',
    value: 'Hull Keyboard Case $150'
  },
  {
    type: 'text',
    value: 'Hull is a bottom mount low profile case for the ketch pcb.'
  },
  {
    type: 'text',
    value: 'These cases are in stock and ready to ship. The following items are included.'
  },
  {
    type: 'list',
    values: [
      'Raw aluminum case',
      '(4) Silver M2x6 screws',
      '(Arrow Layout) FR4 Plate',
      '(JetVan Layout) FR4 Plate',
      '(4) Cork Feet'
    ]
  },
  {
    type: 'image',
    value: hull_img
  }
]

export default HullR1Page;
