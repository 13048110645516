import black_ketch_1_1_front_img from './img/black_ketch_1.1_front.jpg'

const NauticalPcbsPage = [
  {
    type: 'header',
    value: 'Nautical PCB Collection'
  },
  {
    type: 'text',
    value: 'The Ketch PCB is a drop in replacement for the MiniVan PCB supporting many more layout options. The Skiff PCB is a drop in replacement for the roadkit.'
  },
  {
    type: 'image',
    value: black_ketch_1_1_front_img
  }
]

export default NauticalPcbsPage;
