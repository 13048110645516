import aria_img from '../DynamicPage/aria/img/aria.jpg'
import vanu_img from '../DynamicPage/vanu/img/vanu.png'
// import leek_img from './img/leek.jpg'

const data = [
  {
    name: 'Aria',
    img: aria_img,
    description: 'MiniVan compatible case with thic plate and case bottom designed to remove dead space.',
    url: '/page/aria'
  },
  {
    name: 'Vanu',
    img: vanu_img,
    description: 'V4N4G0N R4',
    url: '/page/vanu'
  }
];

export default data;
