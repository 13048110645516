import data from './data'
import CardList from '../../Components/CardList'

function OpenGroupBuys() {
  return (
    <div className="OpenGroupBuys">
      <h3>Open Group Buys</h3>
      {data.length === 0 && <p>No group buys currently running</p>}
      <CardList cards={data} />
    </div>
  );
}

export default OpenGroupBuys
