import skidMiniVan from './img/80skid-minivan-case.jpg'
import acrylicMinivanCase from './img/acrylic-minivan-case.jpg'
import atomicModdedCase from './img/atomic-case-modded-for-big-bat.jpg'
import badpadFront from './img/badpad-front.jpg'
import badpadBack from './img/badpad-back.jpg'
import bowBox from './img/bow-box.jpg'
import bowFull from './img/bow-full.jpg'
import corianderPlate from './img/coriander-plate-peeled.jpg'
import gergoplexHeavy from './img/gergoplex-heavy.jpg'
import gtPlate from './img/gt-plate.jpg'
import iceMinivan from './img/ice-minivan.jpg'
import iceMinivanBack from './img/ice-minivan-back.jpg'
import modelF from './img/new-model-f.jpg'
import roadkitRev1 from './img/roadkit-rev1.jpg'
import roadkitRev3 from './img/roadkit-rev3.jpg'
import sketch from './img/sketch.jpg'
import noEsc from './img/no-esc.jpg'
import skull from './img/skull.jpg'
import skullthulhu from './img/skullthulhu.jpg'
import topreFugu from './img/topre-fugu.jpg'
import trashEsc from './img/trash-esc.jpg'

const ClosetSalePage = [
  {
    type: 'header',
    value: 'Closet Sale'
  },
  {
    type: 'image',
    value: acrylicMinivanCase,
    md: 6
  },
  {
    type: 'text',
    value: '$50 - Comissioned acrylic minivan case'
  },
  {
    type: 'html',
    value: `
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="WEH3U823JC4PL">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  },
  {
    type: 'image',
    value: atomicModdedCase,
    md: 6
  },
  {
    type: 'text',
    value: '$20 - Atomic minivan case modded to support airport shuttle with big battery. includes screws. feet and badge already afixed on the bottom'
  },
  {
    type: 'html',
    value: `
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="FHJZ5UMB5Z8QY">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  },
  {
    type: 'image',
    value: gtPlate,
    md: 6
  },
  {
    type: 'text',
    value: '$10 - Garbage Truck FR4 plate'
  },
  {
    type: 'html',
    value: `
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="HC7MPC6W4MN2S">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  },
  {
    type: 'image',
    value: iceMinivan,
    md: 6
  },
  {
    type: 'image',
    value: iceMinivanBack,
    md: 6
  },
  {
    type: 'text',
    value: '$225 - Custom painted minivan by DeAlba brothers. Includes SA Ice Caps'
  },
  {
    type: 'html',
    value: `
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="LTLS64JHMV4MJ">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  },
  {
    type: 'image',
    value: roadkitRev1,
    md: 6
  },
  {
    type: 'text',
    value: '$20 - never soldered roadkit rev1 pcb (mini b connector)'
  },
  {
    type: 'html',
    value: `
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="DGCV3UTA63BRN">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  },
  {
    type: 'image',
    value: sketch,
    md: 6
  },
  {
    type: 'text',
    value: '$30 - Ketch PCB but the usb is on the top side making it incompatible with minivan cases. see wiki for more info'
  },
  {
    type: 'html',
    value: `
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="7E4DMYJM633N6">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  },
  {
    type: 'image',
    value: noEsc,
    md: 2
  },
  {
    type: 'image',
    value: skull,
    md: 2
  },
  {
    type: 'text',
    value: 'caps are displayed in order No Esc, Skull'
  },
  {
    type: 'html',
    value: `
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="SMGY7PPNKFKVL">
<table>
<tr><td><input type="hidden" name="on0" value="cap">cap</td></tr><tr><td><select name="os0">
	<option value="No Esc">No Esc $25.00 USD</option>
	<option value="Skull">Skull $20.00 USD</option>
</select> </td></tr>
</table>
<input type="hidden" name="currency_code" value="USD">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  }
]

export default ClosetSalePage
