import hub_black_minivan_img from './img/hub_black_minivan.jpg'
import hub_blanks_profile_img from '../hub/img/hub_blanks_profile.png'
import hub_eb_img from './img/hub_eb.jpeg'
import hub_mb44_img from './img/hub_mb44.jpg'
import hub_planck_img from './img/hub_planck.jpg'
import hub_preonic_img from './img/hub_preonic.jpg'
import hub_prime_e_img from './img/hub_prime_e.jpg'
import hub_r2_img from './img/hub_r2.jpg'
import hub_r3_img from './img/hub_r3.jpg'
import hub_r4_img from './img/hub_r4.jpg'
import hub_r5_img from './img/hub_r5.jpg'
import hub_rotary_side_img from './img/hub_rotary_side.jpg'
import hub_rotary_top_img from './img/hub_rotary_top.jpg'
import hub_side_1_img from './img/hub_side_1.jpg'
import hub_side_2_img from './img/hub_side_2.jpg'
import hub_special_van_img from './img/hub_special_van.jpg'
import hub_vanagon_img from './img/hub_vanagon.jpg'
import hub_white_minivan_img from './img/hub_white_minivan.jpg'
import hub_emma_img from './img/hub_emma.jpg'

const HubSpotlightPage = [
  {
    type: 'header',
    value: 'HuB - A keycap profile'
  },
  {
    type: 'text',
    value: 'For the last three years I\'ve worked to develop and produce the HuB keycap profile. Earlier this year I spent a significant portion of my own money to produce 500 prototype sets. These sets support most 40% keyboards. The HuB profile is very similar to cherry and can be mixed with cherry mods very easily for anyone interested in trying HuB on a larger keyboard.'
  },
  {
    type: 'link',
    value: '/page/hub',
    description: 'Purchase HuB Here'
  },
  {
    type: 'header',
    value: 'What is HuB?'
  },
  {
    type: 'text',
    value: 'Simply put, HuB is a cross between DSA and Cherry profiles. With row profiling matching cherry, but spherical tops similar to DSA and SA, HuB not only looks great, but also feels great to type on.'
  },
  {
    type: 'image',
    value: hub_blanks_profile_img
  },
  {
    type: 'image',
    value: hub_side_1_img,
    lg: 6
  },
  {
    type: 'image',
    value: hub_side_2_img,
    lg: 6
  },
  {
    type: 'image',
    value: hub_r2_img,
    lg: 3
  },
  {
    type: 'image',
    value: hub_r3_img,
    lg: 3
  },
  {
    type: 'image',
    value: hub_r4_img,
    lg: 3
  },
  {
    type: 'image',
    value: hub_r5_img,
    lg: 3
  },
  {
    type: 'image',
    value: hub_rotary_side_img,
    lg: 6
  },
  {
    type: 'image',
    value: hub_rotary_top_img,
    lg: 6
  },
  {
    type: 'header',
    value: 'What are people saying about HuB?'
  },
  {
    type: 'quote',
    value: "I'm very much tempted to order a second set of hub",
    author: 'sirknighting',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "I never realized how close to cherry they are. I need to get my Hub on a board",
    author: 'Jake | P3D Store',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "I'm hoping HuB gets enough attention as well. I really like the profile and would like to see some place like TKC make it super accessible. It really is the best of both Cherry and DSA.",
    author: 'mattyice',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "I cant get over how nice HuB feels on my fingertips. My fingers fit perfectly within the sculpt of the homing keys.",
    author: 'mattyice',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "HuB is pretty nice",
    author: 'Window Dump',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "HuB on emma! Looks good, feels good",
    author: 'hOnK Sr.',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "Hub bars very comfy indeed",
    author: 'hOnK Sr.',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "HuB has improved my WPM",
    author: 'PsychicTaco13',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "Hub feels awesome",
    author: 'atari_zero',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'quote',
    value: "Finally getting a chance to try HuB out. Really digging it. Feels really natural to type on.",
    author: 'Annihilator6000',
    lg: 8,
    lgOffset: 2
  },
  {
    type: 'header',
    value: 'User Photos'
  },
  {
    type: 'image',
    value: hub_emma_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_black_minivan_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_white_minivan_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_special_van_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_preonic_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_prime_e_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_vanagon_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_mb44_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_planck_img,
    lg: 4
  },
  {
    type: 'image',
    value: hub_eb_img,
    lg: 4
  }
]

export default HubSpotlightPage;
