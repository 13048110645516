import dnn1_img from './img/dnn1.jpg'
import dnn2_img from './img/dnn2.jpg'
import dnn3_img from './img/dnn3.jpg'
import neon_nights_set_img from './img/neon_nights_set.png'

const DssNeonNightsPage = [
  {
    type: 'header',
    value: 'DSS Neon Nights Keyset $100'
  },
  {
    type: 'text',
    value: 'This is a 40% sculpted keycap set produced by Signature Plastics in the DSS profile.'
  },
  {
    type: 'text',
    value: 'This set is in stock and ready to ship'
  },
  {
    type: 'image',
    value: neon_nights_set_img
  },
  {
    type: 'image',
    value: dnn1_img
  },
  {
    type: 'image',
    value: dnn2_img
  },
  {
    type: 'image',
    value: dnn3_img
  }
]

export default DssNeonNightsPage;
