const data = [
  {
    title: 'HuB Spotlight',
    date: '7-21-2020',
    preview: 'For the last three years I\'ve worked to develop and produce the HuB keycap profile. Earlier this year I spent a significant portion of my own money to produce 500 prototype sets. These sets support most 40% keyboards. The HuB profile is very similar to cherry and can be mixed with cherry mods very easily for anyone interested in trying HuB on a larger keyboard.',
    url: '/page/hub-spotlight'
  },
  {
    title: 'Where did all my keys go?',
    date: '6-9-2016',
    preview: 'So you find yourself looking at these so called 40% keyboards, and all you can do is scratch your head and ask, "How do I do numbers?" A fine question indeed. In this post I want to break down the layout I am using on the MiniVan and alternatives for you.',
    url: '/page/wdamkg'
  }
];

export default data;
