import embrace_img from './img/embrace-assembled.jpg'

const embracePage = [
  {
    type: 'header',
    value: 'Embrace Keyboard Kit $495'
  },
  {
    type: 'image',
    value: embrace_img,
    md: 6,
    mdOffset: 3
  },
  {
    type: 'text',
    value: 'The Embrace keyboard kit is the only keyboard you\'ll ever need. With a super sweet leafspring mounting system, you\'ll have the perfect bounce with every keypress. Numbers 1-6 make any game a breeze. Full Are Gee Bee back-lighting lets all your neighbors know you\'re 1337. The hotswap pcb makes it easy to change your feel whenever you desire.'
  },
  {
    type: 'text',
    value: 'The Embrace keyboard kit includes the following'
  },
  {
    type: 'list',
    values: [
      'Machined acrylic top and bottom',
      'Stainless steel mid',
      'Stainless steel 25mm knob',
      '625 and Split space FR4 plates',
      'Hotswap PCB',
      'Knob daughter board',
      'USB C daughter board',
      'Custom molded feet',
      'Assembly hardware and jst cables'
    ],
    lg:4
  },
  {
    type: 'html',
    value: `<div class="alert alert-info" role="alert">
  Note: Switches, stabilizers, keycaps and usb cable are not included. You will need to source these yourself.
</div>`
  },
  {
    type: 'html',
    value: `<div class="alert alert-warning" role="alert">
  This is a group buy. Manufacturing has not yet started and won't start until orders close on September 9th 2022. Manufacturing is expected to take 3 to 5 months, but could take longer. An update will be posted on this page if additional time is required. If you have any questions about your order please send an email to Tobiasbdan@gmail.com or evan@thevankeyboards.com
</div>`
  }
]

export default embracePage
