import stab_img from './img/425stab.jpg'

const Stab425Page = [
  {
    type: 'header',
    value: '425 Stab 2 pack $10'
  },
  {
    type: 'image',
    value: stab_img,
    md: 6
  },
  {
    type: 'html',
    value: `<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="M343SP3JS7CYW">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  }
]

export default Stab425Page
