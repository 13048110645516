import goon_pcb_img from './img/goon-pcb_render_top.png'
import goon_layouts_img from './img/goon_layouts.png'
import goon_toxic_img from './img/goon-hull_plate_minimal_toxic_crop.jpg'

const goonPage = [
  {
    type: 'header',
    value: 'Goon PCB $45'
  },
  {
    type: 'text',
    value: 'Ever wanted to use standard size keycaps in a minivan footprint? This pcb uses compressed key spacing to fit full size mods into minivan cases.'
  },
  {
    type: 'html',
    value: `<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="PLJYAMV44F7ML">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>`
  },
  {
    type: 'image',
    value: goon_pcb_img,
    md: 4
  },
  {
    type: 'image',
    value: goon_toxic_img,
    md: 4
  },
  {
    type: 'image',
    value: goon_layouts_img,
    md: 4
  },
  {
    type: 'html',
    value: `<div class="alert alert-warning" role="alert">
  Note: this pcb is not compatible with tray mount minivan cases without extreme modification/alternative mounting methods.
</div>`
  }
]

export default goonPage
