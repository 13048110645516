import gt_img from './img/gt.png'

const GarbageTruckPage = [
  {
    type: 'header',
    value: 'Garbage Truck $375'
  },
  {
    type: 'text',
    value: 'Garbage Truck is an 1800 style 40% with a solid titanium knob'
  },
  {
    type: 'text',
    value: 'There are 30 spots available. The Garbage Truck is estimated to ship early May 2021. The kit includes the following'
  },
  {
    type: 'list',
    values: [
      'Black anodized aluminum case top and bottom',
      'Printed Circuit Board',
      'FR4 Plate',
      'Solid Titanium Knob',
      'Rotary Encoder',
      'Grounding wire',
      'M2 screws',
      'Cork feet'
    ]
  },
  {
    type: 'image',
    value: gt_img
  }
]

export default GarbageTruckPage;
