import data from './data'
import CardList from '../../Components/CardList'

function InStock() {
  return (
    <div className="InStock">
      <h3>In Stock</h3>
      <CardList cards={data} />
    </div>
  );
}

export default InStock
