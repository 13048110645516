import dsa_pwork_img from './img/dsa_paperwork.png'

const DsaPaperworkPage = [
  {
    type: 'header',
    value: 'DSA Paperwork Keyset $100'
  },
  {
    type: 'text',
    value: 'This is a 40% uniform keycap set produced by Signature Plastics in the DSA profile.'
  },
  {
    type: 'text',
    value: 'This set is a group buy and will end on January 10th'
  },
  {
    type: 'image',
    value: dsa_pwork_img
  }
]

export default DsaPaperworkPage;