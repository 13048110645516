import aria_img from './img/aria.jpg'

const AriaPage = [
  {
    type: 'header',
    value: 'Aria'
  },
  {
    type: 'image',
    value: aria_img
  }
]

export default AriaPage
