import mv_default_img from './img/mv_default.jpeg'
import mv_layer1_img from './img/mv_layer1.jpeg'
import mv_layer2_img from './img/mv_layer2.jpeg'
import mv_layer3_img from './img/mv_layer3.jpeg'

const WdamkgPage = [
  {
    type: 'header',
    value: 'Where Did All My Keys Go? A Look Into the Function Layers of the MiniVan'
  },
  {
    type: 'text',
    value: 'So you find yourself looking at these so called 40% keyboards, and all you can do is scratch your head and ask, "How do I do numbers?" A fine question indeed. In this post I want to break down the layout I am using on the MiniVan and alternatives for you.'
  },
  {
    type: 'text',
    value: 'The following image is my default layout, the layout of the keys without holding down any function keys.'
  },
  {
    type: 'image',
    value: mv_default_img
  },
  {
    type: 'text',
    value: 'As you can see, for the most part, it is standard qwerty. Caps lock, enter, left alt, and right shift have been replaced with function layer activation keys. I purposely wanted to be able to activate two layers with either hand thereby giving me access to every key on each function layer. If you don\'t need this you can reprogram (a programming guide is in the works) every single key to be whatever you want it to be. Right shift has shifted, pun intended, to the bottom row. I only ever used Control on the left side so the right control went bye bye. Alter was rarely used so I kept the right one to make room on the left for the FN2 key. Kept the left windows key, but again, I only need one so the right windows key is gone. I found that I only hit space with one thumb so moving enter to the other thumb naturally made sense.'
  },
  {
    type: 'text',
    value: 'The following image depicts Function Layer 1 which is activated by the red keys on the default layer.'
  },
  {
    type: 'image',
    value: mv_layer1_img
  },
  {
    type: 'text',
    value: 'By pressing either FN key (the light red) the layer is activated and the keys change from their default behavior to the dark red keys shown above. You can see special characters across the top row, programming characters in the middle row, as well as, arrows. Escape in the bottom left corner, led toggle, and print screen. Alternatively you could put the numbers across the top row. I had my layout setup that way in the beginning, but didn\'t like switching between hands when typing long strings of numbers. Remember, anything you don\'t like placement wise, can be reprogrammed.  Move the exclamation point if you want. Put the arrows somewhere else. It can all be customized 100%.'
  },
  {
    type: 'header',
    value: '"I still don\'t see any numbers!"'
  },
  {
    type: 'text',
    value: 'The following image is my Function 2 layer'
  },
  {
    type: 'image',
    value: mv_layer2_img
  },
  {
    type: 'text',
    value: 'I\'ve got a numpad on the right which I find extremely useful for punching in numbers quickly, the best part being that I don\'t have to move my hands from home row. There are a couple keys for controlling volume, some programming characters as well. The numbers also go across the top row.  I don\'t ever use the numbers that way anymore, but it shows the progression I went through.'
  },
  {
    type: 'text',
    value: 'My layout has changed significantly from the very first iteration. As I have moved things around and tried out different ideas, I have honed in on what I like and what works well for me. Your optimal layout might be drastically different. I encourage you to explore different layouts and to try different configurations. '
  },
  {
    type: 'text',
    value: 'Oh I almost forgot.  I have a lock layer setup for gaming. This allows me to lock in something that doesn\'t really work for typing, but allows me the ability to play my favorite games.'
  },
  {
    type: 'image',
    value: mv_layer3_img
  },
  {
    type: 'text',
    value: 'Can you guess which games I play primarily based on my gaming layer?'
  },
  {
    type: 'text',
    value: 'I hope this post was informative for you and helped give you some ideas for what your optimal layout might be. As always, you\'re welcome to post questions and I\'ll answer to the best of my ability!'
  }
]

export default WdamkgPage;
