import vanu_img from './img/vanu.png'

const VanuPage = [
  {
    type: 'header',
    value: 'Vanu - V4N4G0N R4'
  },
  {
    type: 'image',
    value: vanu_img
  }
]

export default VanuPage
